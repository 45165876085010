<template>
    <div class="add-post">
        <el-row :gutter="3">
            <el-col :span="9">
                <div class="pt20 pb20">
                    预览：
                </div>
                <div class="pd20 post ql-editor">
                    <div class="pb20 fwb">{{form.title}}</div>
                    <div class="createTile" v-if="createTime">发布时间：{{createTime}}</div>
                    <div v-html="form.content"></div>
                </div>

            </el-col>
            <el-col :span="14">
                <el-form ref="form" :model="form" label-width="100px">
                    <el-form-item label="标题" required>
                        <el-input style="" v-model="form.title" placeholder="请在这里输入帖子标题"
                        :disabled="disabled"></el-input>

                    </el-form-item>
                    <el-form-item label="正文" required>
                        <div class="ql-height">
                            <quill-editor
                                    :class="[disabled ? 'is-disabled' : '']"
                                    v-model="form.content"
                                    ref="myQuillEditor"
                                    :options="editorOption"
                                    @blur="onEditorBlur($event)" @focus="onEditorFocus($event)"
                                    @change="onEditorChange($event)"
                                    :disabled="disabled">
                            </quill-editor>
                        </div>

                    </el-form-item>


                    <el-form-item>
                        <el-button type="primary" @click="save()">保存</el-button>
                        <el-button @click="disabled=!disabled">编辑</el-button>
                    </el-form-item>
                </el-form>
            </el-col>
        </el-row>

        <input type="file" @change="getFileUpload($event,'editor')" class="upLoad"/>
    </div>
</template>

<script>
  import Http from '@/service/http';
  import { Base64 } from 'js-base64';
  // 工具栏配置
  const toolbarOptions = [
    ["bold", "italic", "underline", "strike"], // 加粗 斜体 下划线 删除线
    ["blockquote", "code-block"], // 引用  代码块
    [{header: 1}, {header: 2}], // 1、2 级标题
    [{list: "ordered"}, {list: "bullet"}], // 有序、无序列表
    // [{script: "sub"}, {script: "super"}], // 上标/下标
    [{indent: "-1"}, {indent: "+1"}], // 缩进
    // [{'direction': 'rtl'}],                         // 文本方向
    [{size: ["small", false, "large", "huge"]}], // 字体大小
    [{header: [1, 2, 3, 4, 5, 6, false]}], // 标题
    [{color: []}, {background: []}], // 字体颜色、字体背景颜色
    [{font: []}], // 字体种类
    [{align: []}], // 对齐方式
    ["clean"], // 清除文本格式
    // ["link", "image"] // 链接、图片、视频
  ];
  export default {
    name: 'addNewPost',
    data() {
      return {
        content: '',
        editorOption: {
          theme: 'snow',
          placeholder: '在这里开始写正文',
          modules: {
            toolbar: {
              container: toolbarOptions,
              // container: "#toolbar",
              handlers: {
                'image': function () {
                  // 调用iview图片上传
                  document.querySelector('.upLoad').click()
                  this.quill.format('image', false)

                }
              }
            },
          },
        },
        form: {
          title: '',
          content: '',
        },
        disabled:true,
        createTime:''
      }
    },
    created() {
        this.getData()
    },
    mounted() {

    },
    methods: {
      handleSuccess(res) {
        // 获取富文本组件实例
        let quill = this.$refs.myQuillEditor.quill
        // 如果上传成功
        if (res) {
          // 获取光标所在位置
          let length = quill.getSelection().index;
          // 插入图片，res为服务器返回的图片链接地址
          quill.insertEmbed(length, 'image', res)
          // 调整光标到最后
          quill.setSelection(length + 1)
        } else {
          // 提示信息，需引入Message
          this.$message.error('图片插入失败')
        }
      },
      async getFileUpload(event, flag) {//获取文件信息上传
        let file = event.target.files[0]
        const loading = this.$loading({
          lock: true,
          text: 'Loading',
          spinner: 'el-icon-loading',
          background: 'rgba(255, 255, 255, 0.7)'
        });
        let res = await Http.uploadImg({'file': file},[], true)
        if (res.code === 200) {
          this.handleSuccess(res.data.url)
        }
        loading.close()
        // uploadImage(file, 'image_content').then((res) => {
        //   if (res.flag === '1') {
        //     if (flag == 'cover') {
        //       this.form.cover_image = res.data.res
        //     } else {
        //       this.handleSuccess(res.data.res)
        //     }
        //     loading.close()
        //   }
        // })
      },
      onEditorBlur() {
      }, // 失去焦点事件
      onEditorFocus() {
      }, // 获得焦点事件
      onEditorChange() {
      }, // 内容改变事件
      onSubmit(){},
      async getData(){
        let res=await Http.noticeList()
        if(res.code==200){
          this.form.title=res.data.title
          this.form.content=res.data.content
          this.form.id=res.data.id
          this.createTime=res.data.updateTime
        }
      },
      async save(){
        let{
          title,
          content,
          id
        }=this.form

        let res=await Http.noticeSave({
          title,
          content:Base64.encode(content),
          id
        })
        if(res.code==200){
          this.$message.success(res.msg)
          this.disabled=true
          this.getData()
        }
      },
    },
    computed: {
      editor() {
        return this.$refs.myQuillEditor.quill;
      },
    }
  }
</script>

<style lang="scss" scoped>
    .el-form-item {
        margin-bottom: 20px;
    }

    .title {
        font-size: 18px;
        color: #333;
    }

    .tc {
        text-align: center;
    }

    .upLoad {
        opacity: 0;
    }
    .createTile{
        text-align: right;
        font-size: 14px;
        color:#999;
        padding-bottom: 20px;
    }

</style>
